<template>
  <CContainer class="min-vh-100" style="background-color: #ffffff;">
    <CRow>
      <CCard class="text-center text-dark col-md-5" body-wrapper>
        <h4 class="font-weight-normal text-left">{{ coupon.title }}</h4>
        <p class="text-left text-description">{{ coupon.description }}</p>
        <hr />

        <div class="text-left font-weight-normal text-grey p-1">
          <em class="fa fa-smile-o"></em> คะแนน : {{ coupon.redeemFor }} คะแนน
        </div>
        <div class="text-left font-weight-normal text-grey p-1">
          <em class="fa fa-calendar-check-o"></em> วันที่รับคูปอง :
          {{ createdAt }}
        </div>
        <CCard>
          <CCardBody color="light">
            <h5 class="text-dark mt-1">
              รหัสคูปอง : {{ data.redemptionCode }}
            </h5>
            <vue-qrcode
              class="img-fluid"
              :value="qrcodeData"
              style="width:300px"
            />
            <p></p>
            <div class="text-description" >
              ส่วนลด {{ couponValue }} บาท
            </div>
            <div class="text-description" style="font-size: 12px;">
              หมดอายุ {{ expireAt }}
            </div>
          </CCardBody>
        </CCard>

        <hr />
        <div v-if="isUsed === false">
          <p class="text-center text-description">
            กรุณาแสดงคูปองนี้ให้แก่ร้านค้าเพื่อใช้สิทธิ์
          </p>

          <CButton color="success" block @click="popupModal = true" size="lg">
            ใช้คูปอง
          </CButton>
        </div>
        <div v-else-if="isUsed === true">
          <CButton color="danger" block disabled @click="useCoupon" size="lg">
            คูปองถูกใช้งานแล้ว
          </CButton>
        </div>
      </CCard>
    </CRow>
    <CRow>
      <CModal
        :show.sync="popupModal"
        :footer="footer"
        centered
        title="ใช้คูปอง"
      >
      <h5 class="text-center">คุณต้องการใช้งานคูปองใช่หรือไม่?</h5>
        <p class="text-center text-description" style="font-size:14px">
           เมื่อกดใช้คูปองแล้ว จะไม่สามารถใช้คูปองนี้ได้อีก
        </p>
        <template #footer-wrapper>
          <CRow class="justify-content-center">
            <CCol col="5" class="ml-1">
              <CButton
                block
                color="success"
                v-on:click="useCoupon">
                กดใช้คูปอง
              </CButton>
            </CCol>
            <CCol col="5" class="mr-1">
              <CButton block color="danger" @click="popupModal = false">
                ยังไม่ใช้
              </CButton>
            </CCol>
          </CRow>
          <br />
        </template>
      </CModal>
    </CRow>
  </CContainer>
</template>

<script>
import crm from '@/services/crm'
import liff from '@line/liff'
import moment from 'moment'
import VueQrcode from 'vue-qrcode'

export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      data: {},
      member: {},
      coupon: '',
      yourpoint: 0,
      uid: '',
      isUsed: true,
      qrcodeData: '',
      couponValue: 0,
      popupModal: false,
      footer: '',
    }
  },
  computed: {
    objectId() {
      return this.$route.params.objectId
    },
    createdAt() {
      return moment(this.data.created_at).format('LLL')
    },
    expireAt() {
      const expire = this.data.expireAt
      const coupon = this.coupon
      const redeemData = this.data
      if (coupon != null && coupon.dueDuration) {
        return moment(redeemData.created_at).add(coupon.dueDuration, 'm').format('DD MMM YYYY / HH:mm')
      } else if (expire == '' || expire == undefined) {
        return "ไม่จำกัดระยะเวลา"
      }
      return moment(expire).format('LLL')
    },
  },
  mounted() {
    this.getRedemption()
  },
  methods: {
    getRedemption() {
      let objectId = this.objectId

      crm
        .get('/api/v1.0/getredemptionbyobjectId/' + objectId)
        .then((res) => {
          if (res.data.error.code === 0) {
            this.data = res.data.data.documents
            let redemptionCode = this.data.redemptionCode
            let value = this.data.value
            this.coupon = this.data.Coupon
            this.title = this.coupon.title
            this.uid = this.data.uid
            this.isUsed = this.data.isUsed
            this.couponValue = value
            this.qrcodeData = this.objectId + '|' + redemptionCode + '|' + value

            document.title = this.title
          }
        })
        .catch((error) => {
          console.log(error)
        })
    },
    useCoupon() {
      let objectId = this.objectId

      crm
        .post('/api/v1.0/usecoupon/' + objectId)
        .then((res) => {
          this.popupModal = false
          this.isUsed = true
        })
        .catch((error) => {
          console.log(error)
        })
    },
  },
}
</script>
